import React, { useRef, useEffect } from "react";
import * as colors from "../Utils/colors"
import * as defaults from "../Utils/defaults"

export default function Tile(props) {
	const inputRef = useRef(null);

	useEffect(() => {
		if (props.isActiveTile) {
			inputRef.current && inputRef.current.focus();
		} else {
			inputRef.current && inputRef.current.blur()
		}
	});

	const handleChange = (e) => {
		e.preventDefault();
		let validCharsMatcher = /^[0-9a-zA-Z\s]$/;
		if (e.key === "Backspace") {
			props.updateGrid([props.row, props.col], "");
		} else if (validCharsMatcher.test(e.key)) {
			props.updateGrid([props.row, props.col], e.key);
		}
	};
	const handleClick = (e) => {
		props.handleBoardClick([props.row, props.col]);
	};

	let backgroundColor = "white";
	let outlineColor = "rgb(130, 130, 130)";

	if (props.isActiveTile && props.value === defaults.BLACK_SQUARE) {
		backgroundColor = colors.darkGray
	} else if (props.value === defaults.BLACK_SQUARE) {
		backgroundColor = "black"
	} else if  (props.isActiveTile) {
		backgroundColor = colors.highlighted;
	} else if (props.isActiveWord) {
		backgroundColor = colors.primary;
	}

	return (
		<div
			style={{
				outline: `1px solid ${outlineColor}`,
				backgroundColor,
				cursor: "auto",
				position: "relative",
			}}>
			<p style={{position: "absolute", top: 0, fontSize: "calc(6px + 0.4vw)", margin: "0px 0 0 1px", pointerEvents: "none"}}>{props.gridNum}</p>
			<input
				className="mt-1"
				value={props.value}
				style={{
					width: "100%",
					height: "100%",
					border: 0,
					outline: "none",
					fontSize: "min(24px, calc(11px + 1.5vw))",
					textAlign: "center",
					caretColor: "transparent",
					backgroundColor: "transparent",
					cursor: "auto",
					userSelect: "none",

				}}
				onKeyUp={handleChange}
				onClick={handleClick}
				ref={inputRef}
				readOnly
			/>
		</div>
	);
}
