import { Button, Popover, OverlayTrigger, InputGroup, FormControl } from "react-bootstrap";
import { React, useState } from "react";
import * as colors from "../Utils/colors";
import { ButtonGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export default function Header(props) {
	const shareBoardPopover = (
		<Popover id='popover-basic'>
			<Popover.Title as='h3' className='py-3'>
				Copy the link below to share!
			</Popover.Title>
			<Popover.Content>
				<InputGroup className='mb-3'>
					<FormControl value={window.location.href} readOnly />
					<Button
						variant='outline-primary'
						id='button-addon2'
						onClick={(e) => {
							navigator.clipboard.writeText(window.location.href);
						}}>
						Copy
					</Button>
				</InputGroup>
			</Popover.Content>
		</Popover>
	);
	const uploadCustomWordFilePopover = (
		<Popover id='popover-basic'>
			<Popover.Title as='h3' className='py-3'>
				Upload custom word file
			</Popover.Title>
			<Popover.Content>WIP!</Popover.Content>
		</Popover>
	);
	const downloadCrosswordPopover = (
		<Popover id='popover-basic'>
			<Popover.Title as='h3' className='py-3'>
				Download crossword
			</Popover.Title>
			<Popover.Content>WIP!</Popover.Content>
		</Popover>
	);

	const infoPopover = (
		<Popover id='popover-basic'>
			<Popover.Title as='h3' className='py-3'>
				Tips & Tricks
			</Popover.Title>
			<Popover.Content>WIP!</Popover.Content>
		</Popover>
	);


	const buttonStyle = { padding: "2%", margin: 0, border: 0, borderRadius: "5px" };
	return (
		<div
			className='px-3'
			style={{
				backgroundColor: colors.primary,
				minWidth: "100%",
				height: "70px",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				overflowX: "auto",
				gap: "30px",
			}}>
			<ButtonGroup className='mr-3' aria-label='Toolbar with button groups' style={{ gap: "10px" }}>
				<OverlayTrigger trigger='click' rootClose placement='bottom' overlay={downloadCrosswordPopover}>
					<Button variant='outline-dark' style={buttonStyle}>
						<Icon.Download size={"25px"}></Icon.Download>
					</Button>
				</OverlayTrigger>
				<OverlayTrigger trigger='click' rootClose placement='bottom' overlay={uploadCustomWordFilePopover}>
					<Button variant='outline-dark' style={buttonStyle}>
						<Icon.FileEarmarkArrowUp size={"25px"}></Icon.FileEarmarkArrowUp>
					</Button>
				</OverlayTrigger>

				<Button
					variant='outline-dark'
					style={props.symmetryToggled ? { padding: "2%", margin: 0, borderRadius: "5px" } : buttonStyle}>
					<Icon.SymmetryVertical
						size={"25px"}
						onClick={() => {
							props.setSymmetryToggled(!props.symmetryToggled);
						}}></Icon.SymmetryVertical>
				</Button>

				<OverlayTrigger trigger='click' rootClose placement='bottom' overlay={infoPopover}>
					<Button variant='outline-dark' style={buttonStyle}>
						<Icon.Info size={"25px"}></Icon.Info>
					</Button>
				</OverlayTrigger>
			</ButtonGroup>
			<div style={{ display: "flex" }}>
				<input
					placeholder='New Architext Puzzle'
					style={{
						backgroundColor: "transparent",
						outline: 0,
						border: 0,
						borderBottom: "1px solid black",
						fontSize: "32px",
					}}></input>
			</div>

			<OverlayTrigger trigger='click' rootClose placement='bottom' overlay={shareBoardPopover}>
				<Button variant='dark'>Share</Button>
			</OverlayTrigger>
		</div>
	);
}
