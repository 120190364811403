import React, { Component } from "react";
import { database as db } from "../Utils/database";
import { withRouter } from "react-router-dom";
import { BLACK_SQUARE, newGameState } from "../Utils/defaults";
import { Spinner } from "react-bootstrap";
import Board from "../Components/Board";
import Clues from "../Components/Clues";
import Header from "../Components/Header";
function deepcopy(arr) {
	return JSON.parse(JSON.stringify(arr));
}

class AnswerData {
	constructor(gridLocs, gridNum, characters, isAcross) {
		this.gridLocs = gridLocs;
		this.gridNum = gridNum;
		this.characters = characters;
		this.isAcross = isAcross;
	}
}

export class Game extends Component {
	constructor(props) {
		super(props);
		this.state = {
			grid: undefined,
			gridNums: undefined,
			acrossClues: undefined,
			downClues: undefined,
			activeTile: null,
			activeWord: [],
			isAcross: true,
			ready: false,
			symmetryToggled: true,
		};
		this.gameID = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
		this.playerID = "masonzee";
		this.playerColor = "blue";
	}

	async componentDidMount() {
		await this.initializeGame();
		this.setupListeners();
		//removes player from 'online' list when leaving/refreshing page
		window.addEventListener("beforeunload", () => {
			this.writePlayerLeave();
		});
		this.setState({ ready: true });
	}

	/**
	 * Creates the gridNums for a grid.

	 * @param {2D array} grid: 2D array containing entered crossword answers, with BLACK_SQUARE entries representing black squares. 
	 *
   * @returns {Object} gridNums: {String: Int} dictionary. Example key-pair: "1,4": 14
	 */
	numberGrid = (grid) => {
		let curNum = 1;
		let gridNums = {};
		for (let i = 0; i < grid.length; i++) {
			for (let j = 0; j < grid[0].length; j++) {
				if (
					(i === 0 || j === 0 || grid[i - 1][j] === BLACK_SQUARE || grid[i][j - 1] === BLACK_SQUARE) &&
					grid[i][j] !== BLACK_SQUARE
				) {
					gridNums[[i, j]] = curNum;
					curNum += 1;
				}
			}
		}
		return gridNums;
	};

	/**
	 * Pulls data from RT Database, or writes data to RT database if no data exists
	 */
	initializeGame = async () => {
		const snapshot = await db.ref(`${this.gameID}`).get();
		if (snapshot.exists()) {
			const { grid, acrossClues, downClues } = snapshot.val();
			const gridNums = this.numberGrid(grid);
			this.setState({ grid, gridNums, acrossClues, downClues, activeTile: null, activeWord: [] });
		} else {
			await db.ref(`${this.gameID}`).set(newGameState);
			this.setState({ ...newGameState, gridNums: this.numberGrid(newGameState.grid) });
		}
		await this.writePlayerJoin();
	};

	setupListeners = () => {
		let gridRef = db.ref(`${this.gameID}/grid`);
		gridRef.on("child_changed", (data) => {
			this.setState((state, props) => {
				let updatedGrid = deepcopy(state.grid);
				let updatedRow = data.val();
				let rowIndex = data.key;
				updatedGrid[rowIndex] = updatedRow;
				let shouldRecomputeGridnums = false;
				//determines whether the diff is a result of a black square
				for (let j = 0; j < updatedRow.length; j++) {
					if (
						state.grid[rowIndex][j] !== updatedRow[j] &&
						(updatedRow[j] === BLACK_SQUARE || state.grid[rowIndex][j] === BLACK_SQUARE)
					) {
						shouldRecomputeGridnums = true;
					}
				}
				if (shouldRecomputeGridnums) {
					let updatedGridnums = this.numberGrid(updatedGrid);
					return { grid: updatedGrid, gridNums: updatedGridnums };
				} else {
					return { grid: updatedGrid };
				}
			});
		});
		let acrossCluesRef = db.ref(`${this.gameID}/acrossClues`);
		acrossCluesRef.on("child_changed", (data) => {
			//we want gridLocation as an array of 3 values, and we get it as "int,int,int", so we split the array on comma.
			//however, the resultant array is of type [string, string, string], which shouldn't be a problem?
			let gridLocation = data.key.split(",");
			let newClue = data.val();
			this.setState({ acrossClues: { ...this.state.acrossClues, [gridLocation]: newClue } });
		});
		let downCluesRef = db.ref(`${this.gameID}/downClues`);
		downCluesRef.on("child_changed", (data) => {
			//we want gridLocation as an array of 3 values, and we get it as "int,int,int", so we split the array on comma.
			//however, the resultant array is of type [string, string, string], which shouldn't be a problem?
			let gridLocation = data.key.split(",");
			let newClue = data.val();
			this.setState({ downClues: { ...this.state.downClues, [gridLocation]: newClue } });
		});

		let onlinePlayersRef = db.ref(`${this.gameID}/`);
		onlinePlayersRef.on("child_added", (data) => {
			//data.val() is a {newPlayerID: newPlayerColor} object
			let newPlayerID = Object.keys(data.val())[0];
			let newPlayerColor = data.val()[newPlayerID];
			this.setState({ players: { ...this.state.players, [newPlayerID]: newPlayerColor } });
		});
		onlinePlayersRef.on("child_removed", (data) => {
			//data.val() is a {newPlayerID: newPlayerColor} object
			let newPlayerID = Object.keys(data.val())[0];
			let newPlayers = Object.assign({}, this.state.players);
			delete newPlayers[newPlayerID];
			this.setState({ players: newPlayers });
		});
	};

	/**
	 *
	 * @param {Array} gridLocation: [gridX, gridY]
	 * @param {String} newValue: updated user input
	 */
	writeGridUpdate = (gridLocation, newValue) => {
		db.ref(`${this.gameID}/grid/${gridLocation[0]}/${gridLocation[1]}`).set(newValue);
	};

	/**
	 *
	 * @param {Array} clueLocation: [gridX, gridY, clueLength]
	 * @param {String} newValue
	 * @param {Boolean} isAcross
	 */
	writeClueUpdate = (clueLocation, newValue, isAcross) => {
		db.ref(`${this.gameID}/${isAcross ? "acrossClues" : "downClues"}/${clueLocation}`).set(newValue);
	};

	writePlayerJoin = async () => {
		//RTDB Reference Object, stored so we can delete it when leaving the app.
		this.dbPlayerRef = db.ref(`${this.gameID}/players`).push();
		//Add player to list of active players
		await this.dbPlayerRef.set({
			[this.playerID]: this.playerColor,
		});
	};
	writePlayerLeave = () => {
		this.dbPlayerRef.remove();
	};

	//update non-synced data, i.e. activeTile, activeWord, isAcross, etc.
	editState = (deltas) => {
		this.setState(deltas);
	};

	/**
	 * Gets the next tile in the current word
	 * @param {*} currentTile
	 * @returns
	 */
	getNextTile = (currentTile) => {
		let currentTileIdx = -1;
		//get index of currentTile in the currentWord
		for (let i = 0; i < this.state.activeWord.length; i++) {
			const ithTile = this.state.activeWord[i];
			if (ithTile[0] === currentTile[0] && ithTile[1] === currentTile[1]) {
				currentTileIdx = i;
			}
		}
		if (currentTileIdx === this.state.activeWord.length - 1) {
			return currentTile;
		} else {
			return this.state.activeWord[currentTileIdx + 1];
		}
	};

	/**
	 * Gets the previous tile in the current word
	 * @param {*} currentTile
	 * @returns
	 */
	getPreviousTile = (currentTile) => {
		let currentTileIdx = -1;
		//get index of currentTile in the currentWord
		for (let i = 0; i < this.state.activeWord.length; i++) {
			const ithTile = this.state.activeWord[i];
			if (ithTile[0] === currentTile[0] && ithTile[1] === currentTile[1]) {
				currentTileIdx = i;
			}
		}
		if (currentTileIdx === 0) {
			return currentTile;
		} else {
			return this.state.activeWord[currentTileIdx - 1];
		}
	};

	getTileInput = (loc) => {
		return this.state.grid[loc[0]][loc[1]];
	};

	/**
	 *
	 * @param {TileLoc} tile
	 * @param {boolean} isAcross
	 * @returns [TileLoc], a list of TileLocs representing the word
	 */
	getWord = (tile, isAcross, grid) => {
		if (grid === undefined) {
			grid = this.state.grid;
		}
		let wordTiles = [];
		const numRows = grid.length;
		const numCols = grid[0].length;
		if (isAcross) {
			let row = tile[0];
			let variableCol = tile[1];
			while (variableCol > 0 && grid[row][variableCol - 1] !== BLACK_SQUARE) {
				//sets col to the column of the first letter of the word
				variableCol--;
			}
			while (variableCol < numCols && grid[row][variableCol] !== BLACK_SQUARE) {
				wordTiles.push([row, variableCol]);
				variableCol++;
			}
		} else {
			let variableRow = tile[0];
			let col = tile[1];
			while (variableRow > 0 && grid[variableRow - 1][col] !== BLACK_SQUARE) {
				//sets row to the row of the first letter of the word
				variableRow--;
			}
			while (variableRow < numRows && grid[variableRow][col] !== BLACK_SQUARE) {
				wordTiles.push([variableRow, col]);
				variableRow++;
			}
		}
		return wordTiles;
	};

	/**
	 * Manages updates to an input tile, propagating changes to FirebaseDB and updating the active tile
	 *
	 * @param {TileLoc} gridLocation
	 * @param {String} newValue
	 */
	updateGrid = (gridLocation, newValue) => {
		newValue = newValue.toUpperCase();
		let oldValue = this.state.grid[gridLocation[0]][gridLocation[1]];
		let updatedGrid = deepcopy(this.state.grid);
		updatedGrid[gridLocation[0]][gridLocation[1]] = newValue;
		if (newValue === BLACK_SQUARE) {
			// Insert symmetrical black square
			if (this.state.symmetryToggled) {
				const symmetricalSquare = [
					this.state.grid.length - 1 - gridLocation[0],
					this.state.grid[0].length - 1 - gridLocation[1],
				];
				updatedGrid[symmetricalSquare[0]][symmetricalSquare[1]] = BLACK_SQUARE;
				this.writeGridUpdate(symmetricalSquare, BLACK_SQUARE);
			}
			this.setState({
				activeTile: gridLocation,
				activeWord: [],
			});
		} else if (newValue === "" && oldValue === BLACK_SQUARE) {
			// Delete symmetrical black square
			if (this.state.symmetryToggled) {
				const symmetricalSquare = [
					this.state.grid.length - 1 - gridLocation[0],
					this.state.grid[0].length - 1 - gridLocation[1],
				];
				updatedGrid[symmetricalSquare[0]][symmetricalSquare[1]] = "";
				this.writeGridUpdate(symmetricalSquare, "");
			}
			const word = this.getWord(gridLocation, this.state.isAcross, updatedGrid);
			this.setState({ activeTile: word[0], activeWord: word });
		} else if (newValue === "") {
			// Delete tile input
			this.setState({ activeTile: this.getPreviousTile(gridLocation) });
		} else {
			// Insert new tile input
			this.setState({ activeTile: this.getNextTile(gridLocation) });
		}
		this.writeGridUpdate(gridLocation, newValue);
	};

	handleBoardClick = (clickLocation) => {
		if (this.getTileInput(clickLocation) === BLACK_SQUARE) {
			this.setState({ activeTile: clickLocation, activeWord: [] });
		} else if (
			this.state.activeTile &&
			clickLocation[0] === this.state.activeTile[0] &&
			clickLocation[1] === this.state.activeTile[1]
		) {
			// double click
			this.setState({
				activeWord: this.getWord(clickLocation, !this.state.isAcross),
				isAcross: !this.state.isAcross,
			});
		} else {
			//single click
			this.setState({ activeTile: clickLocation, activeWord: this.getWord(clickLocation, this.state.isAcross) });
		}
	};

	/**
	 * Generates all data needed to construct the clue view.
	 *
	 * @returns (AcrossAnswersData, DownAnswersData)
	 */
	getClueViewData = () => {
		// whereas 'word' refers to an array of tilelocations, 'answer' refers to a string or an array of characters
		const primaryAnswersData = [];
		const secondaryAnswersData = [];

		if (!this.state.activeWord || this.state.activeWord.length === 0) {
			return [[], []];
		}
		const primaryAnswerData = new AnswerData(
			this.state.activeWord, // gridLoc
			this.state.gridNums[this.state.activeWord[0]], //gridNum
			this.state.activeWord.map((tileLoc) => this.getTileInput(tileLoc)),
			this.state.isAcross
		); // active answer (array of characters)
		primaryAnswersData.push(primaryAnswerData);

		if (this.state.activeTile) {
			const secondaryWord = this.getWord(this.state.activeTile, !this.state.isAcross); // grid locations
			const gridNum = this.state.gridNums[secondaryWord[0]];
			const secondaryAnswer = secondaryWord.map((tileLoc) => this.getTileInput(tileLoc));
			let secondaryAnswerData = new AnswerData(secondaryWord, gridNum, secondaryAnswer, !this.state.isAcross);
			secondaryAnswersData.push(secondaryAnswerData);
		}
		/* 
		Makes suggestions for all secondary words. Disabled because too CPU intensive. 
		
		for (const tileLoc of this.state.activeWord) {
			const secondaryWord = this.getWord(tileLoc, !this.state.isAcross); // grid locations
			const gridNum = this.state.gridNums[secondaryWord[0]];
			const secondaryAnswer = secondaryWord.map((tileLoc) => this.getTileInput(tileLoc));
			let secondaryAnswerData = new AnswerData(secondaryWord, gridNum, secondaryAnswer, !this.state.isAcross);
			secondaryAnswersData.push(secondaryAnswerData);
		}
		*/

		return this.state.isAcross
			? [primaryAnswersData, secondaryAnswersData]
			: [secondaryAnswersData, primaryAnswersData];
	};

	render() {
		if (!this.state.ready) {
			return (
				<div
					style={{
						width: "100%",
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<Spinner animation='border' />
				</div>
			);
		}
		const [acrossAnswersData, downAnswersData] = this.getClueViewData();

		return (
			<div style={{ width: "100%" }}>
				<Header
					symmetryToggled={this.state.symmetryToggled}
					setSymmetryToggled={(newSymmetryState) => {
						this.setState({ symmetryToggled: newSymmetryState });
					}}
				/>
				<div
					className='p-5'
					style={{
						flex: 1,
						paddingTop: "20px",
						maxWidth: "100vw",
						display: "flex",
						justifyContent: "space-around",
						flexWrap: "wrap",
						alignItems: "center",
						gap: "calc(50px + 10%)",
					}}>
					<Board
						grid={this.state.grid}
						gridNums={this.state.gridNums}
						activeTile={this.state.activeTile}
						activeWord={this.state.activeWord}
						updateGrid={this.updateGrid}
						handleBoardClick={this.handleBoardClick}
						editState={this.editState}
					/>
					<Clues
						acrossAnswers={acrossAnswersData}
						downAnswers={downAnswersData}
						isAcross={this.state.isAcross}
						acrossClues={this.state.acrossClues}
						downClues={this.state.downClues}
						updateGrid={this.updateGrid}
						updateClue={this.writeClueUpdate}
					/>
				</div>
				<p className='pl-5' style={{ width: "100%", textAlign: "left" }}>
					<b>Controls: </b>Press the space bar to insert a black square. Double click to switch direction.{" "}
				</p>
			</div>
		);
	}
}

export default withRouter(Game);
