
  
function createEmptyNxNArr(n) {
    let arr = []
    for (let i = 0; i < n; i++) {
        let row = []
        for (let j = 0; j < n; j++) {
            row.push("")
        }
        arr.push(row)
    }
    
    return arr
}


export const BLACK_SQUARE = " "
export const default15x15Grid = createEmptyNxNArr(15)
export const newGameState = {
    grid: default15x15Grid,
    acrossClues: { "0,0": "" },
    downClues: { "0,0": "" },
}

export const ACTIVE_WORD_COLOR = "#8ecae6"
export const ACTIVE_TILE_COLOR = "#219ebc"
