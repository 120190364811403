import React, { useEffect, useRef } from "react";
import Tile from "./Tile";

// Hook
function useOnClickOutside(ref, handler) {
	useEffect(
		() => {
			const listener = (event) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
				handler(event);
			};
			document.addEventListener("mousedown", listener);
			document.addEventListener("touchstart", listener);
			return () => {
				document.removeEventListener("mousedown", listener);
				document.removeEventListener("touchstart", listener);
			};
		},
		// Add ref and handler to effect dependencies
		// It's worth noting that because passed in handler is a new ...
		// ... function on every render that will cause this effect ...
		// ... callback/cleanup to run every render. It's not a big deal ...
		// ... but to optimize you can wrap handler in useCallback before ...
		// ... passing it into this hook.
		[ref, handler]
	);
}

export default function Board(props) {
	function deactiveGrid() {
		props.editState({ activeTile: null });
	}
	const ref = useRef();
	useOnClickOutside(ref, deactiveGrid);
	const tiles = props.grid.map((row, i) => {
		const tileRow = row.map((item, j) => (
			<Tile
				value={item}
				isActiveTile={JSON.stringify(props.activeTile) === JSON.stringify([i, j])}
				isActiveWord={props.activeWord.find((t) => JSON.stringify(t) === JSON.stringify([i, j]))}
				gridNum={[i, j] in props.gridNums ? props.gridNums[[i, j]] : null}
				updateGrid={props.updateGrid}
				handleBoardClick={props.handleBoardClick}
				row={i}
				col={j}
				key={i + "," + j}
			/>
		));
		return (
			<div key={i} style={{ display: "flex", flex: 1, minHeight: 0 }}>
				{tileRow}
			</div>
		);
	});

	return (
		<div
			ref={ref}
			style={{ //controls Board width
				maxWidth: "650px", 
				width: "100vw",
				minWidth: "350px",
				outline: "4px solid black", 

			}}>
			<div
				style={{ // maintains width aspect ratio, never change. Entire height is padding.
					width: "100%", 
					height: 0,
					paddingBottom: "100%",
					position: "relative",
				}}>
				<div
					style={{ // transforms padding height to normal height.
						position: "absolute",
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}>
					{tiles}
				</div>
			</div>
		</div>
	);
}
