import firebase from "firebase"; // TODO: Replace with your project's config object

var firebaseConfig = {
    apiKey: "AIzaSyBE_reRWzeCCZxUl-CWRomwOdoRDfXbEFM",
    authDomain: "architext-1be28.firebaseapp.com",
    databaseURL: "https://architext-1be28-default-rtdb.firebaseio.com",
    projectId: "architext-1be28",
    storageBucket: "architext-1be28.appspot.com",
    messagingSenderId: "323094467606",
    appId: "1:323094467606:web:74c572d7dede7a52089137"
  };
firebase.initializeApp(firebaseConfig);

// Get a reference to the database service
export var database = firebase.database();

