// Suggestor functionality from http://www.keiranking.com/apps/phil/
let wordsByLength = {};
(function setupWordList() {
    const defaultWordListURL = "https://raw.githubusercontent.com/keiranking/Phil/master/WL-SP.txt"
    let textFile = new XMLHttpRequest();
    textFile.open("GET", defaultWordListURL, true);
    textFile.onreadystatechange = function() {
      if (textFile.readyState === 4 && textFile.status === 200) {  // Makes sure the document is ready to parse, and it's found the file.
        const words = textFile.responseText.split(/\s/g);
        for (let word of words) {
            if (wordsByLength[word.length] === undefined) {
                wordsByLength[word.length] = [word];
            } else {
                wordsByLength[word.length].push(word);
            }
        }
      }
    }
    textFile.send(null);
})();

/**
 *
 * @param {String} partialAnswer String with "_"s for missing input chars
 * @returns List of words that can be legally formed given partialAnswer
 */
export default function getSuggestions(partialAnswer) {
	let enteredInputLength = partialAnswer.replace(/_/g, "").length;
    let wordsWithSameLength = wordsByLength[partialAnswer.length];
	if (enteredInputLength === 0 || enteredInputLength === partialAnswer.length || !wordsWithSameLength) {
		return [];
	}
	let partialAnswerPattern = new RegExp(partialAnswer.replace(/_/g, "\\w"));
	let suggestions = [];
	for (let word of wordsWithSameLength) {
		if (word.search(partialAnswerPattern) > -1) {
			suggestions.push(word);
		}
	}
	return suggestions;
}

export function generateRandomString(numWords) {

    let words = []

    for (let i = 0; i < numWords; i++) {
        const randomWordLengths = Object.keys(wordsByLength)
        const lengthOfRandomWord = randomWordLengths[Math.floor(Math.random()*randomWordLengths.length)]
        const randomWord = wordsByLength[lengthOfRandomWord][Math.floor(Math.random()*wordsByLength[lengthOfRandomWord])]
        words.push(randomWord)
    }
    return words.join("-")
}