import React from "react";
import * as colors from "../Utils/colors";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Github } from "react-bootstrap-icons";
import CrosswordImage from "../Assets/helloworld.png";
import { Link } from "react-router-dom";
import uuid from "react-uuid"
export default function Home() {
	function createTitleLetter(letter, index) {
		return (
			<div
				key={index}
				style={{
					width: "50px",
					height: "50px",
					outline: "2px solid black",
					backgroundColor: "white",
					fontSize: "2.5rem",
					display: "flex",
					alignItems: "flex-end",
					justifyContent: "center",
					fontFamily: "'Roboto Mono', monospace",
				}}>
				<h1 style={{ margin: 0 }}>{letter}</h1>
			</div>
		);
	}

	return (
		<div style={{ width: "100%" }}>
			<div
				className='p-4'
				style={{ display: "flex", justifyContent: "space-between", backgroundColor: colors.primary, overflow: "auto", gap: "5%" }}>
				<div className='ml-3' style={{ display: "flex", alignItems: "flex-end" }}>
					{"ARCHITEXT".split("").map(createTitleLetter)}
				</div>
				<a className='btn btn-outline-dark' style={{ display: "flex" }} href='https://github.com/masonzhang21'>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Github className='mr-2' size={"20px"}></Github>
						<p style={{ margin: 0 }}>Github Repo</p>
					</div>
				</a>
			</div>
			<Container className='p-4' fluid>
				<Row className="m-3">
					<Col md>
						<h4 style={{ paddingTop: "3%", fontWeight: "bold" }}>
							A free, collaborative, web-based crossword puzzle constructor.
						</h4>
						<p style={{ paddingTop: "3%" }} className='mt-3'>
							Designed for collaborative crossword constructing, Architext will instantaneously sync edits
							like a Google Doc and store progress the cloud. Just share the puzzle URL with your
							partners, and remember to bookmark it yourself so you don't lose your progress!
						</p>
						<h5 style={{ paddingTop: "3%" }} className='mt-2'>
							Architext currently supports the following features:{" "}
						</h5>
						<ul>
							<li>Realtime collaborative editing</li>
							<li>Symmetrical black square placement</li>
							<li>Word autosuggestion and autocompletion from dictionary with 150k+ words</li>
						</ul>
						<h5 style={{ paddingTop: "3%" }} className='mt-2'>
							And we're planning on adding:
						</h5>
						<ul>
							<li>Deep learning-powered grid solver</li>
							<li>Puzzle download (.puz file & NYT submission-ready .pdf file)</li>
							<li>Custom word dictionaries</li>
							<li>Local sandboxing</li>
						</ul>
					</Col>
					<Col md>
						<div
							style={{
								paddingTop: "3%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<img src={CrosswordImage} alt='Demo' style={{ width: "500px", maxWidth: "100%" }} />
							<Link
								to={"/games/" + uuid()}
								className='mt-5'
								style={{ width: "500px", maxWidth: "100%" }}>
								<Button variant='info' size='lg' block>
									Create a Puzzle
								</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
