import React from "react";
import AnswerView from "./AnswerView";
import getSuggestions from "../Utils/suggestor";
import * as colors from "../Utils/colors";

export default function Clues(props) {
	function fillGridWithAnswer(gridLocs, answer) {
		if (gridLocs.length !== answer.length) {
			return;
		}
		for (let i = 0; i < answer.length; i++) {
			props.updateGrid(gridLocs[i], answer.charAt(i));
		}
	}

	function createAnswerView(answerData) {
		const clue = props.isAcross
			? props.acrossClues[answerData.gridLocs[0]] || ""
			: props.downClues[answerData.gridLocs[0]] || "";
		const answer = answerData.characters.map((ch) => (ch === "" ? "_" : ch)).join(""); // 'missing' characters are underscored
		const suggestions = getSuggestions(answer);
		return (
			<AnswerView
				key={answerData.gridNum}
				clue={clue}
				answer={answer}
				answerNum={answerData.gridNum}
				suggestions={suggestions}
				isHighlighted={answerData.isAcross === props.isAcross}
				fillGridWithAnswer={(suggestion) => {
					fillGridWithAnswer(answerData.gridLocs, suggestion);
				}}
				updateClue={(newValue) => {
					props.updateClue(answerData.gridLocs[0], newValue, answerData.isAcross);
				}}
			/>
		);
	}
	const acrossAnswerViews =
		props.acrossAnswers && props.acrossAnswers.map((answerData) => createAnswerView(answerData));
	const downAnswerViews = props.downAnswers && props.downAnswers.map((answerData) => createAnswerView(answerData));

	return (
		<div className='p-3' style={{
			flex: 1,
			width: "100%",
			height: "100vh",
			maxHeight: "650px",
			minWidth: "350px",
			minHeight: "350px",
			backgroundImage: `linear-gradient(${colors.contrast}, ${colors.contrast}), linear-gradient(${colors.contrast}, ${colors.contrast}),
			 linear-gradient(${colors.contrast}, ${colors.contrast}), linear-gradient(${colors.contrast}, ${colors.contrast})`,
			backgroundPosition: "top left, top right, bottom left, bottom right",
			backgroundRepeat: "no-repeat",
			backgroundSize: "60px 8px",
			border: `solid ${colors.contrast}`,
			borderWidth: "0px 8px",
			borderRadius: "8px",
		}}>
			<div
				style={{
					width: "100%",
					height: "100%",
					gap: "30px",
					display: "flex",
					flexDirection: "row",
					flexWrap: "wrap",
					overflow: "auto",
				}}>
				<div style={{ flex: 1, display: "flex", flexDirection: "column", maxHeight: "100%", alignItems: "center" }}>
					<h2 style={{ margin: 0 }}>Across</h2>
					<hr
						style={{
							border: `3px solid ${colors.primary}`,
							borderRadius: "5px",
							width: "100px",
							margin: 0,
						}}></hr>
					<div className='my-2' style={{ overflow: "auto", flex: 1 , width: "100%"}}>
						{acrossAnswerViews}
					</div>
				</div>
				<div style={{ flex: 1, display: "flex", flexDirection: "column", maxHeight: "100%", alignItems: "center" }}>
					<h2 style={{ margin: 0 }}>Down</h2>
					<hr
						style={{
							border: `3px solid ${colors.primary}`,
							borderRadius: "5px",
							width: "100px",
							margin: 0,
						}}></hr>
					<div className='my-2' style={{ overflow: "auto", flex: 1, width: "100%"}}>
						{downAnswerViews}
					</div>
				</div>
			</div>
		</div>
	);
}

