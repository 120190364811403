import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Game from './Pages/Game'
import Home from './Pages/Home'


export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/games/:id">
            <Game />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}


