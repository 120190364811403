import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import * as colors from "../Utils/colors";
export default function AnswerView(props) {
	function createSuggestionEntry(suggestion) {
		return (
			<h5
				key={suggestion}
				onDoubleClick={() => {
					props.fillGridWithAnswer(suggestion);
				}}>
				{suggestion}
			</h5>
		);
	}

	const backgroundColor = props.isHighlighted ? colors.highlighted : colors.lightGray;
	return (
		<div
			className='p-2 m-2'
			style={{
				display: "flex",
				flexDirection: "column",
				backgroundColor,
				borderRadius: "10px",
			}}>
			<div className='my-1' style={{ display: "flex", overflow: "auto", alignItems: "flex-end" }}>
				<h5 className='mr-2' style={{ margin: 0, textAlign: "end" }}>
					{props.answerNum + "."}
				</h5>
				<TextareaAutosize
					className=''
					style={{
						fontStyle: "italic",
						resize: "none",
						backgroundColor: colors.darkerGray,
						border: 0,
						borderRadius: "5px",
						outline: 0,
						flex: 1,
					}}
					value={props.clue}
					placeholder={"blank clue"}
					onChange={(e) => props.updateClue(e.target.value)}
				/>
			</div>
			<div className='my-1 mx-3' style={{ display: "flex", flexDirection: "column", fontFamily: "'Roboto Mono', monospace",  }}>
				<h5> <b>{props.answer}</b></h5>
                <div style={{overflow: "auto", maxHeight: "250px"}}>
                {props.suggestions.map(createSuggestionEntry)}

                </div>

			</div>
		</div>
	);
}
